/* stylelint-disable */
body {
  > mat-sidenav-container {
    height: 100%;
  }
}

rk-icon-button button {
  outline: none;
}

input::-moz-focus-inner {
  outline: 0;
}

rk-icon-button button:-moz-focusring {
  outline: 0;
}

a {
  text-decoration: none !important;
}

@keyframes toast-animation-from-top {
  0% {
    transform: translate3d(0, -100%, 0) skewX(-30deg);
    opacity: 0;
  }

  10% {
    transform: skewX(0deg);
    opacity: 1;
  }

  90% {
    transform: skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -100%, 0) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes toast-animation-from-bottom {
  0% {
    transform: translate3d(0, 100%, 0) skewX(-30deg);
    opacity: 0;
  }

  10% {
    transform: skewX(0deg);
    opacity: 1;
  }

  90% {
    transform: skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 100%, 0) skewX(-30deg);
    opacity: 0;
  }
}

.toast-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  top: 30px;

  &.toast-bottom-right {
    top: initial;
    right: 0;
    min-width: 400px;
  }

  .toast.custom-toast {
    animation: toast-animation-from-top 4000ms forwards;
    margin: 6px auto;
    pointer-events: all;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    background-image: none;
    background-color: #fff;
    color: $black;
    padding: 8px 15px 8px 40px;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(177, 177, 177, 0.5);
    width: auto;
    max-width: 90vw;

    &.side-toast {
      animation: toast-animation-from-bottom 4000ms forwards;
      top: -50px;
    }

    .toast-message {
      font-family: Nunito, sans-serif;
      font-size: 14px;
      font-weight: 600;

      &::before {
        content: '';
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        left: 10px;
        top: calc(50% - 10px);
      }
    }

    &.toast-success .toast-message::before {
      background-image: url('/assets/icons/toast_success.svg');
    }

    &.toast-info .toast-message::before {
      background-image: url('/assets/icons/toast_info.svg');
      background-size: 20px;
    }

    &.toast-error .toast-message::before {
      background-image: url('/assets/icons/toast_error.svg');
    }
  }
}

@media screen and (max-width: 767px) {
  .toast-container:not(.toast-bottom-right) {
    top: 20px;
  }
}
