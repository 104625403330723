/* stylelint-disable */
@use '@angular/material' as mat;

/* ---------------------------------------------------------------- */

/*  Avatars
/* ---------------------------------------------------------------- */
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #fff;

  &.square {
    border-radius: 0;
  }

  &.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
  }

  &.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px;
  }

  &.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px;
  }
}

.avatar-wrapper {
  position: relative;

  .avatar {
    margin-top: 0;
    margin-bottom: 0;
  }

  mat-icon.status {
    position: absolute;
    top: 28px;
    left: 28px;
  }
}

mat-icon.status {
  border-radius: 50%;

  &.online {
    color: #4caf50;

    &::before {
      content: 'check_circle';
    }
  }

  &.do-not-disturb {
    color: #f44336;

    &::before {
      content: 'do_not_disturb_on';
    }
  }

  &.away {
    background-color: #ffc107;
    color: #fff;

    &::before {
      content: 'access_time';
    }
  }

  &.offline {
    color: #646464;
    background-color: #fff;

    &::before {
      content: 'not_interested';
    }
  }
}

/* ---------------------------------------------------------------- */

/*  Slides
/*---------------------------------------------------------------- */

body .mat-mdc-slide-toggle .mdc-form-field {
  color: $black;
  font-weight: 400;
}

body .mat-mdc-slide-toggle .mdc-switch {
  height: 20px;
  width: 40px;

  .mdc-switch__handle {
    height: 16px;
    width: 16px;
    background-color: #fff;
    box-shadow: 0 0 2px 0 rgba(88, 88, 88, 0.5);
    left: 2px;
  }

  .mdc-switch__ripple,
  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch__track {
    height: 20px;
    width: 40px;
    border-radius: 10px;
  }

  &:enabled {
    .mdc-switch__track {
      &::before {
        background-color: $gray-medium !important;
      }

      &::after,
      .mdc-switch__track::after {
        border-width: 0 !important;
        background: linear-gradient(45deg, #ff7f50 0%, #ff3648 100%) !important;
      }
    }
  }

  &:enabled .mdc-switch__handle::after {
    background: white !important;
  }

  &:enabled .mdc-switch__shadow {
    box-shadow: 0 0 2px #58585880;
  }
}

/* ---------------------------------------------------------------- */

/*  Checkbox
/*---------------------------------------------------------------- */

body .mat-mdc-checkbox {
  font-size: 15px;
  line-height: 15px;
  height: 15px;

  .mdc-checkbox .mdc-checkbox__native-control {
    height: 18px;
  }

  .mdc-form-field {
    label {
      padding-left: 0;
      line-height: 15px;
      font-size: 14px;
      font-weight: 600;
      color: $black;
      user-select: none;
      white-space: nowrap;
    }

    a {
      color: $brand;
    }
  }

  .mdc-checkbox {
    height: 18px;
    width: 15px;
    font-size: 15px;
    margin-right: 10px;
    padding: 0;
  }

  .mdc-checkbox .mdc-checkbox__ripple {
    opacity: 0 !important;
  }

  .mdc-checkbox .mdc-checkbox__background {
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    border-width: 1px;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate='true']
    )
    ~ .mdc-checkbox__background {
    border-color: $gray-darkest;
    border-width: 1px;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: #fff;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    border-color: #fff;
    transform: scaleX(0.7) rotate(0deg);
  }

  &.mat-mdc-checkbox-checked.mat-accent
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  &.mat-accent
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    background-color: $brand;
    border-color: $brand;
  }

  &.mat-mdc-checkbox-indeterminate.mat-accent.mat-checkbox-disabled
    .mdc-checkbox__background,
  &.mat-mdc-checkbox-checked.mat-accent.mat-checkbox-disabled .mdc-checkbox__background {
    background-color: $brand;
    opacity: 0.7;
  }
  &.mat-mdc-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-frame {
    border: $brand;
    opacity: 0.7;
  }

  &.mat-mdc-checkbox-disabled .mat-checkbox-layout .mat-checkbox-label {
    color: rgba(0, 0, 0, 0.38);
  }

  &.default-weight .mat-checkbox-layout .mat-checkbox-label {
    font-weight: 400;
  }
}

body figure .mat-mdc-checkbox .mdc-checkbox,
body .table-head .mat-mdc-checkbox .mdc-checkbox {
  height: 17px;
  width: 17px;
  padding: 0;
}

/* ---------------------------------------------------------------- */

/*  Radio buttons
/*---------------------------------------------------------------- */
body .mat-mdc-radio-group {
  margin-top: 15px;
}

body .mat-mdc-radio-button {
  color: $black;
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 50px;
  }

  .mdc-radio {
    padding: 5px;
    width: 13px;
    height: 14px;

    .mdc-radio__native-control {
      height: 23px;
      width: 23px;
    }

    &:not(.mdc-radio--disabled) + label {
      cursor: pointer;
    }
  }

  .mdc-radio__background {
    &::before,
    + .mat-radio-ripple.mat-ripple {
      display: none;
    }

    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      height: 13px;
      width: 13px;
    }

    .mdc-radio__inner-circle {
      border-width: 7px;
    }

    .mdc-radio__outer-circle {
      border-width: 1px;
    }
  }

  .mdc-radio .mdc-radio__native-control {
    &:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
    &:enabled + .mdc-radio__background .mdc-radio__inner-circle {
      border-color: $brand !important;
    }

    &:checked + .mdc-radio__background .mdc-radio__inner-circle {
      transform: scale(0.5) translate(-1px, -1px);
    }
  }

  .mdc-form-field > label {
    font-weight: 400;
    user-select: none;
  }
}

body .mat-mdc-radio-group.with-image {
  .mdc-form-field {
    border: 1px solid $gray-lightest;
    padding: 8px;
    border-radius: 4px;

    .mdc-radio {
      align-self: baseline;
    }

    .mdc-label {
      display: flex;
      flex-direction: column;
      margin-left: -28px;
    }
  }

  .mat-mdc-radio-button:not(:last-child) {
    margin-right: 10px;
  }

  .mat-mdc-radio-checked .mdc-form-field {
    border-color: $brand;
    background: $brand10percent;

    img {
      box-shadow: 2px 2px 20px 0 #ff915033;
    }
  }
}

/* ---------------------------------------------------------------- */

/*  Radio buttons
/*---------------------------------------------------------------- */

body .mat-button-toggle-group {
  border: 1px solid #e0e0e0;
}

body .mat-button-toggle {
  border-radius: 4px;
  padding: 9px 12px;
  background: white;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  height: 35px;
  min-width: 85px;
  position: relative;

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0 !important;
  }
  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0 !important;
  }

  .mat-icon {
    color: $black;
    font-size: 12px;
    height: 48px;
    line-height: 48px;
  }

  &.mat-button-toggle-checked {
    color: #fff;
    background: linear-gradient(45deg, #ff7f50 0%, #ff3648 100%);

    .mat-icon {
      color: #fff;
    }
  }

  &.mat-button-toggle-disabled {
    .mat-icon {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

body rk-upgrade-offer-modal .mat-button-toggle-group {
  border: 1px solid white;
  border-radius: 0.25rem;
  font-weight: 600;

  .mat-button-toggle {
    font-size: 1.125rem;
    background: transparent;
    color: #fff;
    padding: 1.375rem 0.75rem;
    line-height: 18px;
    border-radius: 0;

    &.mat-button-toggle-checked {
      color: $brand;
      background: #fff;
      font-weight: 700;

      .tag {
        background: $brand-gradient;
        color: #fff;
      }
    }
  }
}
/* ---------------------------------------------------------------- */

/*  Tooltips
/*---------------------------------------------------------------- */

.mat-tooltip-panel .mat-tooltip {
  max-width: 260px;
  font-size: 14px;
  margin-bottom: 7px;
  overflow: initial;
  background-color: #818181;

  &::after {
    top: 100%;
    left: 50%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 4px solid rgba(0, 0, 0, 0);
    border-top-color: #818181;
    margin-left: -4px;
  }

  &.arrow-end::after {
    left: initial;
    right: 24px;
  }
}

/* ---------------------------------------------------------------- */

/*  Sliders
/*---------------------------------------------------------------- */

body {
  .fix-slider,
  .volume-container {
    .mat-mdc-slider.mdc-slider {
      .mdc-slider__track {
        height: 2px;
      }

      .mdc-slider__track--inactive {
        height: 2px;
      }

      .mdc-slider__track--active {
        height: 3px;
        top: 0;
      }

      .mdc-slider__thumb {
        width: 24px;
        height: 24px;
        top: 12px;
        left: -12px;
        cursor: grab;
      }
    }

    .mat-ripple {
      display: none;
    }

    .mdc-slider__thumb-knob {
      width: 10px;
      height: 10px;
      border-width: 0;
    }

    .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
      background-color: $brand;
    }

    .mat-slider-track-background {
      background-color: $gray-lightest;
      transform: translateX(4px) scale3d(1, 1, 1) !important;
    }
  }

  .volume-container {
    .mat-mdc-slider {
      .mdc-slider__thumb-knob,
      .mdc-slider__track--active_fill {
        border-color: white;
        background-color: white;
      }

      &.mdc-slider .mdc-slider__track--active {
        height: 2px;
      }

      .mdc-slider__track--inactive {
        background-color: white;
        opacity: 0.5;
      }

      &.min-value .mdc-slider__thumb-knob {
        background-color: transparent;
      }
    }

    .mat-slider-track-background {
      background-color: $gray-lightest;
    }
  }

  .mdc-icon-button__ripple {
    display: none;
  }
}

.mat-primary.mat-slider-vertical {
  min-height: 60px;
  width: 20px;

  &.mat-slider-min-value {
    min-height: 56px;
  }

  .mat-slider-thumb {
    background-color: white;
    transform: scale(0.5) !important;
    border: 0;
  }

  .mat-slider-track-background {
    background-color: rgba(255, 255, 255, 0.5);
    transform: translateY(-4px) scale3d(1, 1, 1) !important;
  }

  .mat-slider-wrapper {
    left: 9px;

    .mat-slider-track-wrapper {
      .mat-slider-track-fill {
        background-color: white;
      }
    }
  }
}

.live .mat-primary.mat-slider-vertical {
  min-height: 50px;

  &.mat-slider-min-value {
    min-height: 46px;
  }
}

/* ---------------------------------------------------------------- */

/*  Progress bars / spinners
/*---------------------------------------------------------------- */

.headway-widget .mat-mdc-progress-spinner circle {
  stroke: $gray-medium;
}

.percentage .mat-mdc-progress-spinner circle {
  stroke: $blue-info;
}

.percentage .mat-mdc-progress-spinner[aria-valuenow^='100'] circle {
  stroke: #2ed573;
}

rk-button .flat rk-loader .mat-mdc-progress-spinner circle {
  stroke: $gray-darkest;
}

rk-button .main rk-loader .mat-mdc-progress-spinner circle {
  stroke: $gray-hover;
}

.mat-progress-bar {
  border-radius: 4px;
}

.mat-progress-bar-background {
  fill: $blue-info10percent;
  display: none;
}

.mat-progress-bar-buffer {
  background-color: $blue-info10percent;
}

.mat-progress-bar-fill::after {
  background-color: $blue-info;
}

.mat-progress-bar[aria-valuenow^='100'] .mat-progress-bar-fill::after {
  background-color: #2ed573;
}

.gen-track-progression.mat-progress-bar {
  background-color: $brand10percent;
  height: 6px;
  transform: translateZ(0); // Safari + iOS hack

  .mat-progress-bar-fill::after {
    background-color: $brand;
  }
}

/* ---------------------------------------------------------------- */

/*  Buttons
/*---------------------------------------------------------------- */

body {
  .mat-button {
    border-radius: 2px;
    padding: 5px;
    line-height: 20px;
    min-width: 20px;

    &.mat-primary {
      .mat-button-focus-overlay:hover {
      }
    }
  }

  .mat-fab {
    width: 50px;
    height: 50px;

    .mat-button-wrapper {
      padding: 13px 0;
    }
  }
}

/* ---------------------------------------------------------------- */

/*  Modals
/*---------------------------------------------------------------- */
.cdk-overlay-backdrop.darken {
  background: rgba(0, 0, 0, 0.7);
}

/* ---------------------------------------------------------------- */

/*  Forms
/*---------------------------------------------------------------- */
.form-wrapper {
  background: #fff;
  padding: 16px;

  .form-title {
    font-size: 21px;
    padding: 8px 0;
  }
}

/* ---------------------------------------------------------------- */

/*  Navigation - Simple
/*---------------------------------------------------------------- */
.navigation-simple {
  .item {
    position: relative;
    cursor: pointer;
    text-align: left;
    margin: 0;
    padding: 0 24px;
    text-transform: none;
    line-height: 48px;
    max-height: 48px;
    height: 48px;

    mat-icon {
      margin: 0 16px 0 0;
    }

    .title {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .subheader {
    font-size: 13px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-weight: 500;
    margin-top: 8px;

    &.light {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  mat-divider {
    margin: 8px 0;
  }
}

body {
  .mat-drawer.mat-drawer-side {
    z-index: 3;
  }
}

/* ---------------------------------------------------------------- */

/*  Pagination
/*---------------------------------------------------------------- */
.simple-pagination {
  position: relative;
  display: inline-flex;
  flex-direction: row;

  @include mat.elevation(1);

  background-color: #fff;
  padding: 0 8px;

  .pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    min-height: 56px;
    line-height: 56px;
    border-radius: 0;
    margin: 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54);

    &:hover {
      color: rgba(0, 0, 0, 0.87);
    }

    &.active {
      cursor: default;
      color: rgba(0, 0, 0, 0.87);
    }

    &.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

/* ---------------------------------------------------------------- */

/*  Menu
/*---------------------------------------------------------------- */

.mat-mdc-menu-panel {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25) !important;
  min-height: 46px !important;

  .mat-mdc-menu-content {
    padding-top: 8px;
    padding-bottom: 8px;

    .mat-divider {
      border-top-color: #eee;
      margin: 4px 0 4px 30px;
    }

    .danger {
      color: $error;
    }

    .mat-mdc-menu-item {
      color: $black;
      font-size: 14px;
      line-height: 19px;
      min-height: 0;
      height: initial;
      display: flex;
      align-items: center;
      padding: 10px 30px 10px 15px;

      &:first-child {
        padding-top: 7px;
      }

      &:last-child {
        padding-bottom: 7px;
      }

      .mat-mdc-menu-item-text {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        flex: 1;
      }
    }

    .menu-separator {
      width: calc(100% - 30px);
      height: 1px;
      display: block;
      background-color: $gray-lightest;
      content: '';
      margin-left: auto;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &:not(.icon-search-mat-menu) .mat-mdc-menu-content .mat-icon {
    @include sizeIcon(10px);

    color: $gray-darkest !important;
    margin-right: 10px;

    &.danger {
      color: $error !important;
    }
  }
}

.mat-mdc-menu-panel.user-mat-menu {
  min-width: 200px;
  width: 290px;
  border-radius: 2px;

  .mat-mdc-list {
    padding: 6px 0 0;
  }

  .mat-mdc-menu-content .mat-mdc-menu-item {
    padding: 7px 15px;
  }

  .mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__content {
    height: inherit;
  }

  .mdc-list-item .mdc-list-item__primary-text {
    line-height: 19px;
  }

  .mat-divider {
    margin-left: 15px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  .mat-mdc-menu-item {
    padding-left: 15px;
    height: 35px;
  }
}

.mat-menu-panel.radio-mat-menu {
  margin-top: 10px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    margin-right: 10px;
  }

  .mat-menu-content .mat-menu-item {
    height: 45px;
  }
}

.mat-menu-panel.logout-mat-menu {
  min-width: 250px;
  margin-top: 10px;

  .mat-menu-item {
    height: 40px;
    font-size: 16px;

    .mat-icon {
      @include sizeIcon(16px);

      color: $black !important;
    }
  }
}

.mat-mdc-menu-panel.icon-search-mat-menu {
  min-width: 290px;
}

.mat-mdc-menu-panel.mob-criteria-mat-menu {
  width: calc(100vw - 40px);
  max-width: initial;
}

.radio-incoming-songs-playing-menu {
  width: 320px;
  max-width: 320px;
  min-width: 320px;

  .mat-mdc-menu-content {
    padding: 5px;
  }
}

.overlay-right {
  .mat-mdc-menu-trigger {
    padding: 0;

    .mat-icon svg {
      width: 15px;
      height: 15px;
    }

    &.mat-mdc-icon-button {
      .mat-mdc-button-persistent-ripple,
      &:hover .mat-mdc-button-persistent-ripple::before,
      .mat-mdc-button-ripple {
        display: none;
      }
    }
  }
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0 0 5px 0 rgba(177, 177, 177, 0.5) !important;
}

mat-sidenav .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow: initial;
}

mat-sidenav-content.less-opacity mat-sidenav {
  opacity: 0.3;
  pointer-events: none;
}

/* ---------------------------------------------------------------- */

/*  Table - Simple
/*---------------------------------------------------------------- */
.simple-table-container {
  background: #fff;

  .table-title {
    font-size: 20px;
    padding: 24px;
  }
}

table {
  &.simple {
    width: 100%;
    border: none;
    border-spacing: 0;
    text-align: left;

    thead {
      tr {
        th {
          padding: 16px 8px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.54);
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          white-space: nowrap;

          &:first-child {
            padding-left: 24px;
          }

          &:last-child {
            padding-right: 24px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 16px 8px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);

          &:first-child {
            padding-left: 24px;
          }

          &:last-child {
            padding-right: 24px;
          }
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    &.clickable {
      tbody {
        tr {
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }
      }
    }
  }
}

rk-header .mat-button-focus-overlay {
  opacity: 0 !important;
}

/* ---------------------------------------------------------------- */

/*  Tabs
/*---------------------------------------------------------------- */
body .mat-mdc-tab-group {
  color: $black;

  .mat-mdc-tab-header {
    border-bottom: none;

    .mdc-tab-indicator {
      display: none;
    }

    .mat-mdc-tab-labels {
      border-radius: 3px;
      overflow: hidden;
    }

    .mat-mdc-tab {
      height: 31px;
      min-width: 40px;
      padding: 0;
      background: rgba(71, 71, 71, 0.05);
      opacity: 1;

      &.mdc-tab--active {
        background: #ff9150;
        font-size: 16px;
        font-weight: 700;
        border-radius: 3px;

        .mdc-tab__text-label {
          color: white !important;
          transition: none;
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    text-align: center;
  }
}

/* ---------------------------------------------------------------- */

/*  Expansion panel
/*---------------------------------------------------------------- */
body .mat-expansion-panel {
  .mat-content.mat-content-hide-toggle {
    margin-right: -8px;
  }

  &:not([class*='mat-elevation-z']) {
    box-shadow: none;

    &:not(.parent-panel)::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 20px;
      left: 20px;
      height: 1px;
      background-color: $gray-lightest;
    }

    &:not(.parent-panel).mat-expansion-panel-spacing {
      margin: 0;
      padding-bottom: 20px;
    }
  }

  .mat-expansion-panel-header {
    height: 42px;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  &:not(.parent-panel) {
    .mat-expansion-panel-content {
      padding: 0 20px;
    }

    &:not(.completed) {
      .mat-expansion-panel-header-title {
        color: $blackNew;
      }
    }

    &:not(.completed).mat-expanded {
      .mat-expansion-panel-header-title {
        color: $brand;
      }

      .dot {
        border-color: $brand;
        background: linear-gradient(
          135deg,
          rgba(255, 145, 80, 0.3) 0%,
          rgba(255, 54, 72, 0.3) 100%
        );
      }
    }

    .mat-expansion-indicator::after {
      border-color: $blackNew;
      opacity: 0.5;
    }
  }
}

body .mat-expansion-panel.parent-panel {
  &.mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .mat-expansion-panel-header {
    height: 47px;

    &,
    &:hover:not([aria-disabled='true']),
    &.cdk-program-focused:not([aria-disabled='true']) {
      background: $brand-gradient;
    }
  }
}

body .faq-panel .mat-expansion-panel {
  margin-bottom: 15px !important;

  &,
  &.mat-expansion-panel:first-of-type {
    border-radius: 45px !important;
  }

  .mat-expansion-panel-header {
    min-height: 65px;
    height: initial;
    font-size: 1.25rem;
  }

  .mat-expansion-panel-content {
    padding: 0;
  }

  mat-panel-description {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #252525;
    padding: 20px 30px 0;

    a {
      color: $brand;
    }
  }

  .mat-expansion-indicator {
    margin-top: -3px;
    &::after {
      border-width: 0 3px 3px 0;
    }
  }

  &.mat-expanded {
    mat-expansion-panel-header {
      background-color: #fff2ed;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .mat-expansion-indicator {
      margin-top: 0;
      &::after {
        border-color: #ff9150;
        opacity: 1;
      }
    }
  }
}

body {
  .mdc-button.mat-mdc-button {
    color: #fff;
  }

  .mat-toolbar,
  .mdc-list-item__primary-text,
  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text,
  .mdc-button.mat-mdc-button,
  .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab {
    letter-spacing: normal !important;
  }

  mat-stepper {
    &.mat-stepper-horizontal {
      display: flex;
      background: transparent;
    }

    &.no-header .mat-horizontal-stepper-header-container {
      display: none;
    }

    &.full-height {
      .mat-horizontal-stepper-wrapper {
        flex: 1;
      }

      .mat-horizontal-content-container {
        flex: 1;
        flex-direction: column;
        padding: 0 20px;
      }

      .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
        display: flex;
        height: 100%;
      }
    }
  }
}

@media (hover: hover) and (min-width: 768px) {
  body .mat-mdc-radio-button .mat-mdc-radio-touch-target {
    pointer-events: none;
  }
}
@media (max-width: 767px) {
  body {
    .mat-button-toggle-group {
      font-size: 16px;
      flex-direction: column;
      width: 100%;
    }

    rk-upgrade-offer-modal .mat-button-toggle-group {
      max-width: 95%;
    }

    .mat-button-toggle {
      height: 44px;
    }
  }

  .cdk-overlay-pane.mobile-dialog {
    animation: mobile-dialog-enter 0.5s ease;
    transition: 0.5s ease;
    width: 100%;

    .mdc-dialog--closing {
      animation: mobile-dialog-enter 0.5s ease forwards reverse;
    }

    &.behind-modal {
      transform: translateY(250px);
    }

    .mat-mdc-dialog-surface > * {
      display: block;
      max-height: calc(var(--vh, 1vh) * 100 - 60px);
    }
  }
}

@keyframes mobile-dialog-enter {
  from {
    transform: translateY(calc(100% + 25px));
  }

  to {
    transform: translateY(0);
  }
}
