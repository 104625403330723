@keyframes pulse {
  70% {
    box-shadow: 0 0 0 15px rgba(255, 145, 80, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 145, 80, 0);
  }
}

@keyframes pulse-light {
  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes appear-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear-disappear {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes congrats {
  0% {
    opacity: 0;
    margin-left: -20px;
  }

  15% {
    margin-left: 5px;
  }

  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    margin-left: 5px;
  }
}
