.cdk-custom-container {
  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }
}

.cdk-icon-search-container .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 8px;
}
