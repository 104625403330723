// stylelint-disable selector-class-pattern
// Fullcalendar custom theme ; Custom classes are marked with a comment
:root {
  --fc-small-font-size: 14px;
  --fc-border-color: #{$gray-lightest};
  --fc-today-bg-color: transparent;
  --fc-now-indicator-color: red;
  --break-hour-column: 80px;
}

full-calendar.fc {
  @media (prefers-reduced-motion: no-preference) {
    // Custom class
    &.fc-smooth-scrolling {
      .fc-scroller {
        scroll-behavior: smooth;
      }

      .fc-scroller.fc-scroller-liquid-absolute {
        z-index: 0;
      }
    }
  }

  .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  .fc-timegrid-now-indicator-line {
    border-top-width: 2px;
  }

  .fc-scrollgrid {
    border: 0;
  }

  .fc-timegrid-axis {
    border: 0;

    /* TODO Partial borders on edges
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      right: 0;
      width: 7px;
      border-bottom: 1px solid var(--fc-border-color);
    }
    */
  }

  .fc-col-header-cell {
    border-left: 0;
    border-right: 0;
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 15px;

    /* TODO Partial borders on edges
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 11px;
      border-left: 1px solid var(--fc-border-color);
    }
    */
  }

  .fc-col-header-cell-cushion {
    color: $black;
    text-transform: uppercase;
    display: block;
    padding: 0;
  }

  // Custom class
  .fc-col-header-cell-cushion-container {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
  }

  // Custom class
  .fc-col-header-cell-cushion-day {
    color: $gray-darkest;
  }

  .fc-day-today {
    // Custom class
    .fc-col-header-cell-cushion-day {
      color: $brand;
    }

    // Custom class
    .fc-col-header-cell-cushion-date {
      color: #fff;
      border-radius: 11.5px;
      background: linear-gradient(40.65deg, $brand 0%, #ff3648 100%);
      padding: 2px 4px;
    }
  }

  // Custom class
  .fc-col-header-cell-cushion-actions {
    display: none;
  }

  // Custom class
  .fc-col-header-cell-day-split-columns {
    display: none;
  }

  .fc-timegrid-slot,
  .fc-timegrid-slot-lane-plus-button-wrapper {
    height: 30px;
  }

  .fc-timegrid-slot-label {
    border: 0;
    font-size: 12px;

    /* TODO Partial borders on edges
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      right: 0;
      width: 7px;
      border-bottom: 1px solid var(--fc-border-color);
    }
    */
  }

  .fc-timegrid-slot-label-frame {
    margin-top: -22px;
    margin-right: 17px;
  }

  // Custom class
  .fc-timegrid-slot-lane-plus-button-wrapper {
    display: flex;
    height: 100%;
  }

  // Custom class
  .fc-timegrid-slot-lane-plus-button {
    background: rgba($brand, 0.1);
    cursor: pointer;
    border-radius: 4px;
    color: #ff784f;
    font-size: 29px;
    outline: none;
    opacity: 0;
    transition: opacity 0.25s;
    flex: 1;
    line-height: 30px;

    &:hover {
      opacity: 1;
    }
  }

  .fc-timegrid-slots {
    tr:first-child {
      .fc-timegrid-slot-label-frame {
        display: none;
      }
    }

    tr {
      color: #25252580;
    }

    tr:hover .fc-timegrid-slot-label {
      color: $brand;
    }
  }

  .fc-timegrid-col-events {
    margin: 0;
  }

  .fc-timegrid-event {
    padding: 4px 8px;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 10px 12px;

    .fc-selected-icon {
      width: 0;
    }

    &:hover,
    // Custom class
    &.fc-event-extended-active {
      opacity: 0.95;
    }

    &.fc-timegrid-event-short .fc-event-time::after {
      content: '';
    }
  }

  .fc-event-main {
    overflow: hidden;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: bottom 3px right 3px;
  }

  .fc-event-main-frame {
    height: initial;

    &.fc-event-short {
      flex-direction: row;

      .fc-event-title {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .fc-event-time {
    margin: 0;
    white-space: break-spaces;
  }

  .fc-event-title {
    font-weight: 700;
  }

  .fc-v-event .fc-event-resizer-end {
    cursor: ns-resize;
  }

  // Day view
  .fc-timeGridDay-view {
    .fc-col-header-cell {
      padding-bottom: 40px;
      position: relative;
    }

    // Custom class
    .fc-col-header-cell-day-split-columns {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 39px;
      display: flex;
      align-items: center;
    }

    // Custom class
    .fc-col-header-cell-day-split-column {
      flex: 1;
    }

    .fc-timegrid-slots {
      tr {
        color: $gray-darkest;
      }

      tr:nth-child(6n + 1) {
        color: inherit;
      }
    }

    .fc-timegrid-slot-lane {
      background: linear-gradient(
        90deg,
        #fff calc(50% - 1.5px),
        $gray-lightest,
        #fff 50%
      );
    }

    .fc-timegrid-col-bg {
      display: none;
    }

    .fc-timegrid-event-harness {
      width: 50%;
      left: 0 !important; /* stylelint-disable-line declaration-no-important */
      right: 0 !important; /* stylelint-disable-line declaration-no-important */
      margin-right: 0 !important; /* stylelint-disable-line declaration-no-important */
      z-index: initial !important; /* stylelint-disable-line declaration-no-important */

      // Custom attribute (because css :has() is not supported yet)
      &[data-has-fc-event-extended-type-break] {
        left: initial !important; /* stylelint-disable-line declaration-no-important */
      }

      // Custom attribute (because css :has() is not supported yet)
      &[data-has-fc-event-extended-type-none] {
        display: none;
      }
    }

    .fc-timegrid-event {
      // Custom class
      &.fc-event-extended-type-break {
        left: initial;
        width: calc(100% - 1px);
        box-shadow: 0 0 0 1px #fff;
        padding-left: 10px;
        background-image: none;

        .fc-event-main {
          z-index: initial;
          background-image: none;
        }

        .fc-event-main-frame {
          flex-direction: row;

          // Custom attribute
          /* stylelint-disable-next-line max-nesting-depth */
          &[data-extra-breaks-count]::after {
            top: calc(50% - 16px);
          }
        }

        .fc-event-time {
          margin-right: 10px;
          opacity: 0.8;
          font-size: 14px;
        }

        .fc-event-title {
          font-weight: initial;
          font-size: 14px;
        }
      }
    }
  }

  &.break-shell--calendar {
    .fc-view table > colgroup,
    .fc-timegrid-axis {
      display: none;
    }

    &.fc-theme-standard td,
    &.fc-theme-standard th {
      border: none;
    }
  }
}
