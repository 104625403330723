/* You can add global styles to this file, and also import other style files */

/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

@tailwind base;

@layer base {
  img,
  svg {
    display: initial;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
    font-weight: 700;
    margin: 0 0 1rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 700;
  }

  /* Component host styles used before Tailwind,
   * but causes Tailwind class not working
  */
  rk-icon-button {
    display: inline-block;
  }

  rk-button {
    display: inline-block;
  }
}

@tailwind components;
@tailwind utilities;

// Theme variables, must be included before the libraries to allow overriding defaults
@import 'theme';
@import 'core';

// 3rd party libraries
// @import '~material-design-icons-iconfont/dist/fonts/material-icons.css';

@import '@fortawesome/fontawesome-free/css/all.css';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'ngx-toastr/toastr';
@import 'swiper/swiper-bundle.min.css';

:root {
  --vh: 1vh;
}

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

.fab {
  font-weight: initial;
}

// stylelint-disable selector-id-pattern, selector-class-pattern
.headway-widget {
  #HW_badge {
    background: $brand;
    font-size: 0;
    width: 12px;
    height: 12px;
    left: 23px;

    &.HW_softHidden {
      opacity: 0;
    }
  }

  #HW_badge_cont {
    height: 50px;
    width: 35px;
  }
}
// stylelint-enable selector-id-pattern, selector-class-pattern

// Hide clock icon on input time in webkit browsers
input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}

@media (hover: hover) and (min-width: 1024px) {
  input[type='time']::-webkit-calendar-picker-indicator {
    pointer-events: none;
  }
}
