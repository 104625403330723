@import 'theme';
// stylelint-disable
.color-picker {
  border-color: $gray-medium !important;
  border-radius: 4px;

  &::after,
  &::before {
    bottom: 100%;
    left: 16px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
  }

  &::before {
    border-color: rgba(217, 217, 217, 0);
    border-bottom-color: $gray-medium;
    border-width: 6px;
    margin-left: -6px;
  }

  .arrow {
    display: none;
  }

  .saturation-lightness .cursor {
    width: 12px;
    height: 12px;
    margin-left: 1px;
    border-color: white;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  }

  .hue .cursor {
    width: 6px;
    margin-left: 5px;
    border-radius: 4px;
    background: white;
    border: none;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  }

  .hue-alpha {
    .left,
    .right {
      padding-bottom: 0;
    }
  }

  .hex-text {
    padding: 0 8px;

    .box {
      padding: 0 8px 8px !important;
    }

    .box input {
      height: initial;
      padding: 8px 10px !important;
      border-radius: 4px;
      border: 1px solid $gray-medium !important;
      margin-top: 10px;
      margin-bottom: 5px;
      color: $black;
      font-size: 14px;
      text-transform: uppercase;
    }

    .box:last-child {
      display: none;
    }
  }

  .type-policy {
    display: none;
  }
}
