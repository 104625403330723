/* stylelint-disable */
$border-width: 3px;
$nav-backround-selected: #f9f9f9;
// map-get($background, hover)
$hover-menu: $nav-backround-selected;
$hover-sous-menu: $gray-medium;

.nav {
  background-color: #fff;

  .nav-subheader {
    display: flex;
    align-items: center;
    height: 48px;
    font-weight: 500;
    padding-left: 24px;
    margin-top: 8px;
    font-size: 12px;
    white-space: nowrap;
  }

  .nav-group {
    display: block;

    > .group-title {
      position: relative;
      display: flex;
      align-items: center;
      height: 48px;
      font-weight: 500;
      padding-left: 24px;
      margin-top: 8px;
      font-size: 12px;
      white-space: nowrap;
      color: $black;
    }
  }

  .nav-collapse {
    .nav-item {
      .nav-link {
        height: 40px;
      }

      .nav-link:not(.locked).active {
        border-left: $border-width solid transparent;
        color: $brand;
      }
    }

    &.open {
      .nav-link::after {
        content: none;
      }
    }

    .children {
      background-color: $gray-lightest;

      .nav-link.active {
        background-color: $gray-lightest;
      }

      .nav-link::after {
        content: none;
      }
    }
  }

  > .nav-item:last-child {
    .nav-link::after {
      content: none;
    }
  }

  .nav-item {
    &.has-child-open {
      > .nav-link {
        border-left: $border-width solid $brand;
        background-color: $nav-backround-selected;

        .nav-link-icon {
          color: $brand;
        }
      }

      .nav-item {
        .nav-link {
          height: 40px;
        }

        .nav-link.active {
          border-left: $border-width solid transparent;
        }
      }
    }

    .nav-link {
      text-decoration: none !important;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 15px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      color: $black;
      border-left: $border-width solid transparent;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100% - 45px);
        height: 1px;
        display: block;
        background-color: #eee;
      }

      > .nav-link-title {
        @apply text-base md:text-sm;

        flex: 1;
        white-space: nowrap;
      }

      .mat-ripple-element {
        background-color: $hover-menu;
      }

      .mat-icon {
        color: $gray-darkest;
        transition: margin-left 200ms ease;
      }

      &:not(.locked).active {
        border-left: $border-width solid $brand;
        background-color: $nav-backround-selected;

        .mat-icon {
          color: $brand;
        }

        .mat-ripple-element {
          background-color: $black10percent;
        }

        // &,
        .nav-link-icon {
          color: $brand;
        }

        .nav-link-badge {
          background: #fff !important;
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }

      .nav-link-icon {
        margin-right: 13px;
        font-size: 15px;
        width: 18px;
        height: 15px;
        min-width: 18px;
        min-height: 15px;
        line-height: 15px;
        text-align: center;
      }

      .collapse-arrow {
        font-size: 16px;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        line-height: 16px;
      }

      .collapse-arrow {
        color: $gray-medium;
      }
    }

    &.nav-collapse {
      display: block;

      &.mob-hidden {
        @apply opacity-40 md:opacity-100;
      }

      /*
      > .children {
        > .nav-item {
          > .nav-link,
          &.nav-group > .group-title,
          &.nav-group > .group-items > .nav-item > .nav-link {
            padding-left: 56px;
          }

          > .children {
            > .nav-item {
              > .nav-link,
              &.nav-group > .group-title,
              &.nav-group > .group-items > .nav-item > .nav-link {
                padding-left: 72px;
              }
            }
          }
        }
      }

 */
    }
  }

  > .nav-item {
    &.nav-collapse {
      background: transparent;
      transition: background 200ms ease-in-out;

      &.open {
        background: $nav-backround-selected;
      }
    }
  }

  > .nav-group {
    > .group-items {
      > .nav-collapse {
        background: transparent;
        transition: background 200ms ease-in-out;

        &.open {
          background: $nav-backround-selected;
        }
      }
    }
  }
}

@media (pointer: fine) and (min-width: 768px) {
  .nav-collapse .nav-link .children .nav-link {
    &:hover,
    &.active:hover {
      background-color: $hover-sous-menu;
    }
  }

  .nav-item .nav-link:hover {
    background-color: $hover-menu;
  }

  .nav-collapse .nav-item .nav-link {
    &:hover {
      background-color: $hover-sous-menu;
    }

    &.active:hover {
      background-color: $hover-sous-menu;
    }
  }
}
