/* stylelint-disable declaration-no-important */
// ######################
// POSITION HELPERS
// ######################
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .position#{$infix}-relative {
      position: relative;
    }

    .position#{$infix}-absolute {
      position: absolute;
    }

    .position#{$infix}-static {
      position: static;
    }
  }
}

// ####################################
// ABSOLUTE POSITION ALIGNMENT HELPERS
// ####################################
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .align#{$infix}-top {
      top: 0;
    }

    .align#{$infix}-right {
      right: 0;
    }

    .align#{$infix}-bottom {
      bottom: 0;
    }

    .align#{$infix}-left {
      left: 0;
    }
  }
}

// ######################
// SIZE HELPERS
// ######################
@each $prop, $abbrev in (height: h, width: w) {
  // Percentage
  @for $i from 0 through 20 {
    $i-p: 5 * $i;
    $size-p: 5% * $i;

    .#{$abbrev}-#{$i-p}-p {
      #{$prop}: $size-p !important;
    }
  }
}

// ######################
// SPACING HELPERS
// ######################
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

// ######################
// BORDER HELPERS
// ######################
$border-style: 1px solid rgba(0, 0, 0, 0.12);

.border-top,
.bt {
  border-top: $border-style;
}

.border-right,
.br {
  border-right: $border-style;
}

.border-bottom,
.bb {
  border-bottom: $border-style;
}

.border-left,
.bl {
  border-left: $border-style;
}

.border-horizontal,
.b-x {
  border-left: $border-style;
  border-right: $border-style;
}

.border-vertical,
.by {
  border-top: $border-style;
  border-bottom: $border-style;
}

// ######################
// BORDER RADIUS HELPERS
// ######################
.border-radius-100 {
  border-radius: 100%;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-16 {
  border-radius: 16px;
}

// ######################
// CURSOR HELPERS
// ######################
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.svg-brand {
  g {
    fill: $brand;
  }
}

.svg-brand-disabled {
  g {
    fill: #ffc0a8;
  }
}

.svg-gray-dark {
  g {
    fill: $gray-darkest;
  }
}

.svg-gray-medium {
  g {
    fill: $gray-medium;
  }
}

.svg-gray-light {
  g {
    fill: $gray-lightest;
  }
}

.svg-black {
  g {
    fill: $black;
  }
}
