.ngx-contextmenu.cdk-overlay-pane {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 2px;

  .dropdown-menu {
    list-style: none;
    padding: 4px 0;
    margin: 0;
  }

  .divider {
    width: 100%;
    height: 1px;
    display: block;
    background-color: $gray-lightest;
    content: '';
    margin-left: auto;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .divider ~ .divider {
    width: calc(100% - 30px);
  }

  li:not(.divider) {
    color: $black;
    font-size: 14px;
    line-height: 14px;
    min-height: 30px;
    display: flex;
    align-items: center;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    text-decoration: none;
    position: relative;

    .mat-icon {
      @include sizeIcon(10px);

      color: $gray-darkest;
      margin-right: 10px;

      &.danger {
        color: $error;
      }
    }

    span {
      color: $black;

      &.danger {
        color: $error;
      }
    }

    span.passive {
      padding: 10px 11px 10px;
    }

    a {
      min-height: 30px;
      padding: 0 11px 0;
      display: flex;
      flex: 1;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: $gray-hover;
      }
    }
  }
}

.ngx-contextmenu.cdk-overlay-pane .ctx-program {
  min-width: 160px;

  .divider {
    width: calc(100% - 30px);
  }

  li:not(.divider) {
    a {
      min-height: 39px;
    }
  }
}

.ngx-contextmenu.cdk-overlay-pane .ctx-planning {
  min-width: 190px;
  padding: 8px;

  li:not(.divider) {
    a {
      min-height: 39px;
    }
  }
}
